body .ant-modal {
  padding-bottom: 0;
  border: none !important;
}

body .ant-modal .ant-modal-close {
  display: none;
}

body .ant-modal .ant-modal-body {
  /*padding: 0;*/
  position: relative;
  /* overflow: hidden; */
}

body .ant-modal .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.85);
}

body .ant-modal .ant-modal-content {
  border-radius: 8px;
}

body .ant-select-dropdown {
  border-radius: 10px;
  box-shadow: rgba(82, 0, 255, 0.1) 0px 8px 30px !important;
  padding: 0;
}

body .ant-select-item-option {
  font-size: var(--font-size__medium);
  font-weight: 400;
}

body .ant-select-item-option:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 0;
}

body .ant-form-item-explain.ant-form-item-explain-error {
  font-size: var(--font-size__small);
  padding: 4px 0;
}

body .ant-select-item-option-content .option-custom {
}
body .ant-select-item-option-content .option-custom .title {
  margin: 0;
}
body .ant-select-item-option-content .option-custom .subTitle {
  color: var(--sub-text-color);
  font-size: var(--font-size__small);
  font-weight: 300;
}

.confirm-content {
  text-align: center;
}

.confirm-title {
  font-size: 18px;
  font-weight: bold;
  color: #e40000;
}

.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0px !important;
}

.ant-modal-confirm .ant-modal-confirm-btns button {
  margin-left: 20px !important;
  border-radius: 6px;
  text-transform: uppercase;
}
