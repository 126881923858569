:root {
  --icon-color: #92929d;
  --primary-color: #fafafb;
  --secondary-color: #ffffff;
  --highlight-color: #ee161f;
  --disabled-color: #bfbfbf;
  --text-color: #3e3e3e;
  --text-bolder-color: #484848;
  --label-form-color: rgba(170, 170, 170, 1);
  --text-secondary-color: #dbdbdb;
  --text-tertiary-color: #b5b5be;
  /* quaternary, quinary, senary, septenary, octonary, nonary, and denary, */
  --border-tree-color: #dfdfdf;
  --border-list-color: #d2d2d2;
  --border-application-detail-color: #d7d7d7;
  --sub-text-color: #aaaaaa;
  --text-title-table-color: #484848;
  --icon-table-color: #200e32;
  --link-color: #4877ef;
  --hover-color: var(--link-color);
  --link-border-color: #0062ff;
  --btn-primary-color: var(--link-color);
  --btn-disabled-color: #f3f3f3;
  --bg-header-dialog-color: rgba(72, 119, 239, 0.05);
  --bg-icon-notification: #f8f8f8;
  --border-notification-color: #ececec;
  --bg-file-upload: var(--bg-icon-notification);
  --border-file-upload: #e1e1e1;
  --icon-file-upload-color: #aaaaaa;
  --bg-notification-readed: #f2f7ff;
  --font-size__small: 12px;
  --font-size__medium: 14px;
  --font-size__large: 18px;
  --bg-application-icon: linear-gradient(
    324.11deg,
    #e0e6ed 1.35%,
    #eaf9ff 43.52%,
    #ffffff 96.38%
  );
  --box-shadow-application-icon: 0px 3.74811px 14.9924px
      rgba(136, 152, 167, 0.15),
    inset 0px -0.906801px 1.45088px #dbf7ff,
    inset 0px -2.44326px 3.35076px rgba(147, 200, 217, 0.12),
    inset 0px 1.815px 3.35076px rgba(255, 255, 255, 0.54);
}

* {
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
  font-family: 'Open Sans', sans-serif;
}

html {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100vh;
}

body {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100vh;
  overflow: hidden;
  scroll-behavior: smooth;
  margin: 0 !important;
  padding: 0 !important;
  font-size: var(--font-size__medium) !important;
  font-weight: 400;
}

img {
  width: 100%;
  height: auto;
}

#root,
#root > .container {
  width: 100%;
  height: 100%;
}

button,
p {
  margin: 0 !important;
}

.fullWidth {
  width: 100% !important;
}

.fullHeight {
  height: 100% !important;
}

.inline-flex {
  display: inline-flex !important;
}

.flex-row {
  display: flex;
  justify-content: center;
}

.flex-row__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row__between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-row__start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-row__end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-row__around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-row__align-start {
  align-items: flex-start !important;
}

.flex-row__align-end {
  align-items: flex-end !important;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-col__center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-col__between {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}

.flex-col__around {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
}

.flex-col__start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex-col__end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.flex-col__align-start {
  justify-content: flex-start !important;
}

.flex-col__align-end {
  justify-content: flex-end !important;
}

.bgImageCover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bgImageContain {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed;
}

.text-ellipsis {
  text-overflow: ellipsis;
  /*white-space: nowrap;*/
  white-space: pre-wrap;
  overflow: hidden;
  width: 100%;
}

.hide-scrollbar::-webkit-scrollbar {
  /* This is the magic bit for WebKit */
  display: none;
}

.required:after {
  position: absolute;
  content: '*';
  right: -10px;
  top: -3px;
  color: red;
}

.link {
  color: var(--link-color);
  font-size: var(--font-size__medium);
  font-weight: 300;
}

.icon-back {
  font-size: 16px;
  margin-right: 14px;
  cursor: pointer;
}

.text-bold {
  font-weight: 600;
}

.btn-toggle-sidebar {
  position: fixed;
  top: 89px;
  width: 40px;
  height: 40px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
  background: var(--btn-primary-color);
  color: #fff;
  border: none;
  border-radius: 50%;
  z-index: 1000;
  left: calc(20.83333333% - 22px);
}
.admin.sidebarShow .btn-toggle-sidebar {
  transform: scale(1);
}
.admin.sidebarHidden .btn-toggle-sidebar {
  transform: scale(-1);
  left: 0px;
  width: 20px;
  height: 60px;
  border-radius: 5px 0px 0px 5px;
}
@media (min-width: 1600px) {
  .btn-toggle-sidebar {
    left: calc(12.5% - 22px);
  }
}
@media (max-width: 1600px) {
  .btn-toggle-sidebar {
    left: calc(16.6% - 22px);
  }
}
@media (max-width: 1200px) {
  .btn-toggle-sidebar {
    left: calc(20.8% - 22px);
  }
}
@media (max-width: 992px) {
  .btn-toggle-sidebar {
    left: calc(25% - 22px);
  }
}
@media (max-width: 767px) {
  .btn-toggle-sidebar {
    left: calc(33.3% - 22px);
  }
}
@media (max-width: 576px) {
  .admin.sidebarShow .btn-toggle-sidebar {
    left: calc(10% - 22px);
    transform: scale(-1);
    left: 0;
    border-radius: 5px 0px 0px 5px;
    width: 20px;
    height: 60px;
  }
  .admin.sidebarHidden .btn-toggle-sidebar {
    left: calc(90% - 22px);
    transform: scale(1);
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }
}

.wrapperSidebar {
  transition: 0.5s;
  /* overflow: hidden; */
}
.admin.sidebarShow .wrapperSidebar {
  /* display: block; */
  /* max-width: 100%; */
}

.admin.sidebarHidden .wrapperSidebar {
  /* display: block; */
  /* max-width: 100%; */
}

.git-version {
  position: absolute !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
  padding: 12px 25px !important;
  font-size: 12px !important;
  color: #757575 !important;
  background: #fff !important;
}

.wrapOvflX {
  overflow: auto;
}
